<template>
  <div>
    <page-title>Create Tasks</page-title>
    <page-subtitle>Create a new task here</page-subtitle>
    <div class="mt-5">
      <div class="bg-white p-5 rounded">
        <section-subtitle> Enter task details </section-subtitle>
        <div class="flex flex-col">
          <form class="grid grid-cols-1 md:grid-cols-2 gap-5">
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Title</label
              >
              <t-input
                v-model="app.title"
                class="focus:outline-none"
                type="text"
                aria-autocomplete="no"
              ></t-input>
            </div>
            <div class="form-control mt-7">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Assign Consultant</label
              >
              <v-select
                class="py-1"
                :options="staffs"
                label="name"
                v-model="app.consultantId"
                :reduce="staff => staff._id"
              ></v-select>
            </div>
            <div>
              <p class="mb-4 text-gray-600 font-semibold text-xs">Duration</p>
              <div class="grid grid-cols-2 gap-4">
                <div class="form-control">
                  <label
                    class="text-xs font-semibold text-gray-800 mb-2 block"
                    for="name"
                    >Start Date</label
                  >
                  <t-input
                    v-model="app.startDate"
                    class="focus:outline-none"
                    type="date"
                    aria-autocomplete="no"
                  ></t-input>
                </div>
                <div class="form-control">
                  <label
                    class="text-xs font-semibold text-gray-800 mb-2 block"
                    for="name"
                    >Start Time</label
                  >
                  <t-input
                    v-model="app.startTime"
                    class="focus:outline-none"
                    type="time"
                  ></t-input>
                </div>
                <div class="form-control">
                  <label
                    class="text-xs font-semibold text-gray-800 mb-2 block"
                    for="name"
                    >End Date</label
                  >
                  <t-input
                    v-model="app.dueDate"
                    class="focus:outline-none"
                    type="date"
                    aria-autocomplete="no"
                  ></t-input>
                </div>
                <div class="form-control">
                  <label
                    class="text-xs font-semibold text-gray-800 mb-2 block"
                    for="name"
                    >End Time</label
                  >
                  <t-input
                    v-model="app.dueTime"
                    class="focus:outline-none"
                    type="time"
                  ></t-input>
                </div>
              </div>
            </div>
            <div class="grid md:mt-7 grid-cols-1 gap-4">
              <div class="form">
                <label
                  class="text-xs font-semibold text-gray-800 mb-2 block"
                  for="name"
                  >Priority</label
                >
                <t-select
                  v-model="app.priority"
                  class="focus:outline-none"
                  type="text"
                  :options="['Low', 'Normal', 'High', 'Urgent']"
                  aria-autocomplete="no"
                ></t-select>
              </div>
            </div>

            <div class="form">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Description</label
              >
              <t-textarea
                v-model="app.description"
                rows="5"
                class="focus:outline-none"
                type="type"
                aria-autocomplete="no"
              ></t-textarea>
            </div>
          </form>
          <div class="flex space-x-5 items-center justify-end mt-5">
            <t-button
              @click="$router.go(-1)"
              class="border w-28 border-accent px-3 py-1 rounded text-accent"
              variant="error"
            >
              Cancel
            </t-button>
            <t-button
              @click="setTask"
              class="w-28 flex items-center justify-center space-x-4"
            >
              <span>Save</span> <spinner v-if="loading"></spinner>
            </t-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageSubtitle from '../../components/Typography/PageSubtitle.vue'
import PageTitle from '../../components/Typography/PageTitle.vue'
import SectionSubtitle from '../../components/Typography/SectionSubtitle'
import Spinner from '../../components/Utils/Spinner'
import staffApi from '../../api/userMgt'
import taskApi from '../../api/tasks'

export default {
  name: 'Tasks',
  components: { PageTitle, PageSubtitle, SectionSubtitle, Spinner },
  data: () => ({
    staffs: [],
    app: {},
    loading: false
  }),
  created() {
    this.getStaffs()
  },
  methods: {
    async setTask() {
      this.loading = true
      const res = await taskApi.tasks().create({
        ...this.app,
        priority: this.app.priority.toLowerCase() || 'low'
      })
      if (res.error) {
        this.$store.commit('alert', {
          error: true,
          status: true,
          message: res.errorMessage
        })
        this.loading = false
        return
      }
      this.$store.commit('alert', {
        error: false,
        status: true,
        message: res.message
      })
      this.app = {}
      this.app.description = ''
      this.loading = false
    },
    async getStaffs() {
      const res = await staffApi.user().getAll()
      this.staffs = res.data.data
        .filter(c => c.role === 'consultant' && c.status === 'active')
        .map(c => ({
          ...c,
          name: c.firstName + ' ' + c.lastName
        }))
    }
  }
}
</script>

<style></style>
