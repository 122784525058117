import axios from 'axios'
import { BASEURL } from '../const'
import Store from '../../store'

import { successHandler, errorHandler } from '../handlers'

const URL = `${BASEURL}/tasks`

export default {
  tasks() {
    return {
      create: async data => {
        const endpoint = `${URL}`
        try {
          const res = await axios.post(endpoint, data, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getAll: async () => {
        const endpoint = `${URL}?populate=client`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getOne: async ({ taskId }) => {
        const endpoint = `${URL}/${taskId}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      update: async ({ taskId }, status) => {
        const endpoint = `${URL}/${taskId}`
        try {
          const res = await axios.put(
            endpoint,
            { status },
            {
              headers: {
                ...Store.getters.getRequestHeaders.headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
